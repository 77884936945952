import React from "react"
import styled from "styled-components";

const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

const TextElement = styled.p`
  color: #000;
  font-size: 1.2rem;
  font-family: 'Cardo', sans-serif;
  font-weight: 500;
  line-height: 1.7;

  @media screen and (min-width: 450px) {
    font-size: 1rem;
  }
`

type Props = {
  children: React.ReactNode;
}

const Text = ({ children }: Props) => (
  <Wrapper>
    <TextElement>
      {children}
    </TextElement>
  </Wrapper>
)

export default Text
