import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Title from "../components/elements/title"
import Text from "../components/elements/text"
import BlogFeed from "../components/blogFeed"
import Thumbnail from '../components/notionThumbnail'
import CustomLink from '../components/elements/link'
import SEO from "../components/seo"
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allNotionJson {
        edges {
          node {
            title
            author
            authorLink
            link
            thumbnail
          }
        }
      }
    }
  `)

  const templates = data.allNotionJson.edges

  return (
    <Layout>
      <SEO title="Notion" />
      <Title size="h2">Notion</Title>
      <Text>You can read my blogpost about Notion <CustomLink href="https://bit.ly/3qQm8NV" alt="Notion Medium blogpost" targetBlank>here</CustomLink> (in Czech language) or visit official <CustomLink href="https://www.notion.so/" alt="Notion website" targetBlank>Notion website</CustomLink>.</Text>
      <Title size="h3">Template install</Title>
      <Text>Visit template page, click on "Duplicate" button in top bar and copy it into your workspace.</Text>
      <Title size="h3">Top templates</Title>
      <Container>
        {
          templates.map(edge => (
            <Thumbnail
              key={edge.node.title}
              image={edge.node.thumbnail}
              title={edge.node.title}
              author={edge.node.author}
              link={edge.node.link}
              authorLink={edge.node.authorLink}
            />
          ))
        }
      </Container>
      <Text>
        See more templates on <CustomLink href="https://www.notion.so/Notion-Template-Gallery-181e961aeb5c4ee6915307c0dfd5156d" alt="Notion template gallery" targetBlank>Notion template gallery website</CustomLink>.
      </Text>
    </Layout>
  )
}

export default BlogPage
