import React from "react"
import styled from "styled-components"
import CustomLink from "./elements/link"

const Wrapper = styled.div`
  padding: 0.5rem;
`

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid #b3dec1;
  overflow: hidden;

`
const ThumbnailLink = styled.a`
  text-decoration: none;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: #b3dec1;
  }
`

const ThumbnailImageWrapper = styled.div`
  height: 12rem;
  overflow: hidden;
`

const ThumbnailImage = styled.div<{ bg: string }>`
  width: 100%;
  height: 100%;
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center left;
  text-decoration: none;
  filter: grayscale(40%);

  &:hover {
    filter: grayscale(0);
    transition: transform .4s, filter 1s ease-in-out;
    transform: rotate(-6deg) scale(1.3);
  }

  @media screen and (min-width: 540px) {
    &:hover {
      transform: rotate(-10deg) scale(1.3);
    }
  }
`

const ThumbnailTitleWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.7rem;
`

const ThumbnailAuthorLink = styled.a`
  color: #C64561;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  margin: 0 0.3rem;
  border-bottom: 1px solid #fff;

  &:hover {
    transition: all .25s ease-in-out;
    border-bottom: 1px solid #C64561;
  }

  @media screen and (min-width: 450px) {
    font-size: 0.95rem;
  }

  @media screen and (min-width: 500px) {
    font-size: 0.8rem;
  }
`

const ThumbnailTitle = styled.span`
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  word-break: break-word;

  @media screen and (min-width: 450px) {
    font-size: 0.95rem;
  }

  @media screen and (min-width: 500px) {
    font-size: 0.8rem;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  @media screen and (min-width: 900px) {
    margin-top: 0.5rem;
  }
`

type Props = {
  image: string,
  title: string,
  author: string,
  authorLink: string,
  link: string,
}

const Thumbnail = ({ image, title, author, authorLink, link }: Props) => (
  <Wrapper>
    <Container>
      <ThumbnailLink href={link} alt={title} target="_blank">
        <ThumbnailImageWrapper>
          <ThumbnailImage bg={image} />
        </ThumbnailImageWrapper>
      </ThumbnailLink>
        <ThumbnailTitleWrapper>
          <ThumbnailTitle>{title}</ThumbnailTitle>
          <ThumbnailTitle>
            Author:
            <ThumbnailAuthorLink href={authorLink} target="_blank">{author}</ThumbnailAuthorLink>
          </ThumbnailTitle>
          <LinkWrapper>
            <CustomLink href={link} targetBlank navLink>Download</CustomLink>
          </LinkWrapper>
        </ThumbnailTitleWrapper>
    </Container>
  </Wrapper>
)

export default Thumbnail
